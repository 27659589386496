import wretch from 'wretch';
import { retry } from 'wretch/dist/middlewares/retry';
import FormDataAddon from "wretch/dist/addons/formData"
import QueryStringAddon from "wretch/dist/addons/queryString"
import { printStackTrace } from './printStackTrace';

function getWretchInstance(requestRetry) {
  if (requestRetry) {
    return wretch().middlewares([
      retry({
        delayTimer: 500,
        maxAttempts: 2,
      })
    ]);
  } return wretch();
}

function fetchRequestBuilder({ options = {}, endpoint, query = {}, requestRetry = false } = {}) {
  if (endpoint) {
    const fetch = getWretchInstance(requestRetry)
    .addon(QueryStringAddon)
    .addon(FormDataAddon);
    
    return fetch
      .options(options)
      .query(query)
      .url(endpoint);
  }
  throw new Error(`Invalid endpoint url : ${endpoint}`);
}

/**
 * @example
 *  fetch('/signup', {
          method: 'POST',
          body: JSON.stringify({
            name, email, password,
          }),
          credentials: 'same-origin',
        }).then((res)=> res.json()).then(json);
 * // is equivalent to
 *  makePostRequest({
          data: {
            name, email, password,
          },
          endpoint: '/signup',
          options: { credentials: 'same-origin' },
        }).then(json);
 *
 * @param {object} param0
 * @param {object} param0.data - data could be your JSON object
 * @param {string} param0.endpoint
 * @param {object} param0.options - { credentials: 'same-origin' }
 * @returns {object} - JSON object as returned by your endpoint
 */
async function makePostRequest({ data, headers = {}, ...rest } = {}) {
  try {
    if (data) {
      const response = await fetchRequestBuilder({ ...rest })
        .headers({...headers, 'Content-Type': headers.contentType || 'application/json' })
        .post(data);
      return response.json();
    }
  } catch (error) {
    printStackTrace(error);
    throw error;
  }
  throw new Error(`Invalid payload : ${JSON.stringify(data)}`);
}

/**
 * @example
 *  fetch('/signup', {
          method: 'POST',
          body: JSON.stringify({
            name, email, password,
          }),
          credentials: 'same-origin',
        }).then((res)=> res.json()).then(json);
 * // is equivalent to
 *  makePostRequest({
          data: {
            name, email, password,
          },
          endpoint: '/signup',
          options: { credentials: 'same-origin' },
        }).then(json);
 *
 * @param {object} param0
 * @param {object} param0.data - data could be your JSON object
 * @param {string} param0.endpoint
 * @param {object} param0.options - { credentials: 'same-origin' }
 * @returns {object} - JSON object as returned by your endpoint
 */
async function makePutRequest({ data, ...rest } = {}) {
  try {
    if (data) {
      const response = await fetchRequestBuilder({ ...rest })
        .put(data);
      return response.json();
    }
  } catch (error) {
    printStackTrace(error);
    throw error;
  }
  throw new Error(`Invalid payload : ${JSON.stringify(data)}`);
}


/**
 *
 * @param {object} fetchObjectOptions
 * @param {string} fetchObjectOptions.endpoint
 * @param {object} fetchObjectOptions.query
 * @param {object} fetchObjectOptions.options
 */
async function makeGetRequest({ expectedResponseType = 'json', headers = {}, ...fetchObjectOptions } = {}) {
  try {
    const response = await fetchRequestBuilder({ ...fetchObjectOptions })
      .headers(headers)
      .get();
    switch (expectedResponseType) {
      case 'text':
        return response.text();
      default:
        return response.json();
    }
  } catch (error) {
    printStackTrace(error);
    throw error;
  }
}

/**
 *
 * @param {object} fetchObjectOptions
 * @param {string} fetchObjectOptions.endpoint
 * @param {object} fetchObjectOptions.query
 * @param {object} fetchObjectOptions.options
 */
async function makeDeleteRequest({ expectedResponseType = 'json', ...fetchObjectOptions } = {}) {
  try {
    const response = await fetchRequestBuilder({ ...fetchObjectOptions })
      .delete();
    switch (expectedResponseType) {
      case 'text':
        return response.text();
      default:
        return response.json();
    }
  } catch (error) {
    printStackTrace(error);
    throw error;
  }
}

export {
  makePostRequest, makeGetRequest, makeDeleteRequest, makePutRequest
};
