// import Raven from 'raven-js';
import Debugout from './debugout';

function ErrorLogger() {
  this.errorLogger = new Debugout();
}

ErrorLogger.prototype.log = function log(...args) {
  this.errorLogger.log(...args);
};

const logger = new ErrorLogger();
export default logger;
