const sanitizer = ['name', 'imageUrl', 'token', 'p2ptoken', 'token'];

/* source: https://github.com/inorganik/debugout.js/ */
/*
    debugout.js
    by @inorganik
*/
function debugout() {
  const self = this;
  // OPTIONS
  self.realTimeLoggingOn = true; // log in real time (forwards to console.log)
  self.useTimestamps = false; // insert a timestamp in front of each log
  self.useLocalStorage = false; // store the output using window.localStorage() and continuously add to the same log each session
  self.recordLogs = true; // set to false after you're done debugging to avoid the log eating up memory
  self.autoTrim = true; // to avoid the log eating up potentially endless memory
  self.maxLines = 500; // if autoTrim is true, this many most recent lines are saved
  self.tailNumLines = 100; // how many lines tail() will retrieve
  self.logFilename = 'debugout.txt'; // filename of log downloaded with downloadLog()
  self.maxDepth = 1; // max recursion depth for logged objects

  // vars
  self.depth = 0;
  self.parentSizes = [0];
  self.currentResult = '';
  self.startTime = new Date();
  self.output = '';
  self.isErrorLog = false;

  const consoleLog = console.log;
  const consoleError = console.error;

  this.version = () => '0.5.0';

  /*
  USER METHODS
  */
  this.getLog = function getLog() {
    let retrievalTime = new Date();
    // if recording is off, so dev knows why they don't have any logs
    if (!self.recordLogs) {
      self.log('[debugout.js] log recording is off.');
    }
    // if using local storage, get values
    if (self.useLocalStorage) {
      let saved = window.localStorage.getItem('debugout.js');
      if (saved) {
        saved = JSON.parse(saved);
        self.startTime = new Date(saved.startTime);
        self.output = saved.log;
        retrievalTime = new Date(saved.lastLog);
      }
    }
    return `${self.output
    }\n---- Log retrieved: ${retrievalTime} ----\n${
      self.formatSessionDuration(self.startTime, retrievalTime)}`;
  };
  // accepts optional number or uses the default for number of lines
  this.tail = function tail(numLines) {
    const numOfLines = numLines || self.tailLines;
    return self.trimLog(self.getLog(), numOfLines);
  };
  // accepts a string to search for
  this.search = function search(string) {
    const lines = self.output.split('\n');
    const rgx = new RegExp(string);
    const matched = [];
    // can't use a simple Array.prototype.filter() here
    // because we need to add the line number
    for (let i = 0; i < lines.length; i+=1) {
      const addr = `[${i}] `;
      if (lines[i].match(rgx)) {
        matched.push(addr + lines[i]);
      }
    }
    let result = matched.join('\n');
    if (result.length === 0) result = `Nothing found for "${string}".`;
    return result;
  };
  // accepts the starting line and how many lines after the starting line you want
  this.getSlice = function getSlice(lineNumber, numLines) {
    const lines = self.output.split('\n');
    const segment = lines.slice(lineNumber, lineNumber + numLines);
    return segment.join('\n');
  };
  // immediately downloads the log - for desktop browser use
  this.downloadLog = function downloadLog() {
    let file = 'data:text/plain;charset=utf-8,';
    const logFile = self.getLog();
    const encoded = encodeURIComponent(logFile);
    file += encoded;
    const a = document.createElement('a');
    a.href = file;
    a.target = '_blank';
    a.download = self.logFilename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  // clears the log
  this.clear = function clear() {
    const clearTime = new Date();
    self.output = `---- Log cleared: ${clearTime} ----\n`;
    if (self.useLocalStorage) {
      // local storage
      let saveObject = {
        startTime: self.startTime,
        log: self.output,
        lastLog: clearTime,
      };
      saveObject = JSON.stringify(saveObject);
      window.localStorage.setItem('debugout.js', saveObject);
    }
    if (self.realTimeLoggingOn) console.info('[debugout.js] clear()');
  };
  // records a log
  this.log = function log(...args) {
    // const [obj] = args;
    // log in real time
    if (self.realTimeLoggingOn) {
      consoleLog(...args);
    }
    // record log
    args.forEach((l, index) => {
      const type = self.determineType(l);
      if (type === 'Error') consoleError(l);
      if (type != null && self.recordLogs) {
        const addition = self.formatType(type, l);
        // timestamp, formatted for brevity
        if (self.useTimestamps) {
          const logTime = new Date();
          self.output += self.formatTimestamp(logTime);
        }
        self.output += `${addition}${index === args.length - 1 ? '\n' : ' '}`;
        if (self.autoTrim) self.output = self.trimLog(self.output, self.maxLines);
        // local storage
        if (self.useLocalStorage) {
          const last = new Date();
          let saveObject = {
            startTime: self.startTime,
            log: self.output,
            lastLog: last,
          };
          saveObject = JSON.stringify(saveObject);
          window.localStorage.setItem('debugout.js', saveObject);
        }
      }
      self.depth = 0;
      self.parentSizes = [0];
      self.currentResult = '';
    });
  };
  /*
  METHODS FOR CONSTRUCTING THE LOG
  */

  // like typeof but classifies objects of type 'object'
  // kept separate from formatType() so you can use at your convenience!
  this.determineType = function determineType(object) {
    
    if(object && !object.constructor){
      // eslint-disable-next-line no-undef
      log('error ::::: object without constructor property: ', object);
    }

    if (object != null) {
      let typeResult;
      const type = typeof object;
      if (type === 'object') {
        const len = object.length;
        if (len == null) {
          if (typeof object.getTime === 'function') {
            typeResult = 'Date';
          } else if (typeof object.test === 'function') {
            typeResult = 'RegExp';
          } else if ((object.message && object.stack) || (object.constructor && object.constructor.name.toLowerCase().indexOf('error') !== -1)) {
            typeResult = 'Error';
          } else {
            typeResult = 'Object';
          }
        } else {
          typeResult = 'Array';
        }
      } else {
        typeResult = type;
      }
      return typeResult;
    }
    return null;
  };
  // format type accordingly, recursively if necessary
  this.formatType = function formatType(type, obj) {
    if (self.maxDepth && self.depth >= self.maxDepth) {
      return '... (max-depth reached)';
    }
    switch (type) {
      case 'Error':
        return `${obj.constructor.name}: ${obj.message}\n${obj.stack}`;
      case 'Object':
        if (self.maxDepth === 0) return obj.constructor.name;
        // if (obj.constructor !== Object) {
        //   return obj.constructor.name;
        // }
        self.currentResult += '{\n';
        self.depth += 1;
        self.parentSizes.push(Object.keys(obj).length);
        Object.entries(obj).forEach(([key, objValue], index) => {
          self.currentResult += self.indentsForDepth(self.depth);
          self.currentResult += `${key}: `;
          let subresult = '';
          if (!sanitizer.includes(key)) {
            const subtype = self.determineType(objValue);
            subresult = self.formatType(subtype, objValue);
          } else subresult = '**sanitized**';
          if (subresult) {
            self.currentResult += subresult;
            if (index !== self.parentSizes[self.depth] - 1) self.currentResult += ',';
            self.currentResult += '\n';
          } else {
            if (index !== self.parentSizes[self.depth] - 1) self.currentResult += ',';
            self.currentResult += '\n';
          }
        });
        self.depth -= 1;
        self.parentSizes.pop();
        self.currentResult += self.indentsForDepth(self.depth);
        self.currentResult += '}';
        if (self.depth === 0) return self.currentResult;
        return '';
      case 'Array':
        self.currentResult += '[';
        self.depth += 1;
        self.parentSizes.push(obj.length);
        obj.forEach((v, index) => {
          const subtype = self.determineType(v);
          if (subtype === 'Object' || subtype === 'Array') self.currentResult += `\n${self.indentsForDepth(self.depth)}`;
          const subresult = self.formatType(subtype, v);
          if (subresult) {
            self.currentResult += subresult;
            if (index !== self.parentSizes[self.depth] - 1) self.currentResult += ', ';
            if (subtype === 'Array') self.currentResult += '\n';
          } else {
            if (index !== self.parentSizes[self.depth] - 1) self.currentResult += ', ';
            if (subtype !== 'Object') self.currentResult += '\n';
            else if (index === self.parentSizes[self.depth] - 1) self.currentResult += '\n';
          }
        });
        self.depth -= 1;
        self.parentSizes.pop();
        self.currentResult += ']';
        return self.currentResult;
      case 'RegExp':
        return `/${obj.source}/`;
      case 'Date':
      case 'string':
        if (self.depth > 0 || obj.length === 0) {
          return `"${obj}"`;
        }
        return obj;

      case 'boolean':
        if (obj) return 'true';
        return 'false';
      case 'number':
        return `${obj}`;
      default:
        return `${obj}`;
    }
  };
  this.indentsForDepth = function indentsForDepth(depth) {
    let str = '';
    for (let i = 0; i < depth; i+=1) {
      str += '\t';
    }
    return str;
  };
  this.trimLog = function trimLog(log, maxLines) {
    let lines = log.split('\n');
    if (lines.length > maxLines) {
      lines = lines.slice(lines.length - maxLines);
    }
    return lines.join('\n');
  };
  this.lines = function lines() {
    return self.output.split('\n').length;
  };
  // calculate testing time
  this.formatSessionDuration = function formatSessionDuration(startTime, endTime) {
    let msec = endTime - startTime;
    const hh = Math.floor(msec / 1000 / 60 / 60);
    const hrs = (`0${hh}`).slice(-2);
    msec -= hh * 1000 * 60 * 60;
    const mm = Math.floor(msec / 1000 / 60);
    const mins = (`0${mm}`).slice(-2);
    msec -= mm * 1000 * 60;
    const ss = Math.floor(msec / 1000);
    const secs = (`0${ss}`).slice(-2);
    msec -= ss * 1000;
    return `---- Session duration: ${hrs}:${mins}:${secs} ----`;
  };
  this.formatTimestamp = function formatTimestamp(timestamp) {
    const year = timestamp.getFullYear();
    const date = timestamp.getDate();
    const month = (`0${timestamp.getMonth() + 1}`).slice(-2);
    const hrs = Number(timestamp.getHours());
    const mins = (`0${timestamp.getMinutes()}`).slice(-2);
    const secs = (`0${timestamp.getSeconds()}`).slice(-2);
    return `[${year}-${month}-${date} ${hrs}:${mins}:${secs}]: `;
  };

  /*
  START/RESUME LOG
  */
  if (self.useLocalStorage) {
    let saved = window.localStorage.getItem('debugout.js');
    if (saved) {
      saved = JSON.parse(saved);
      self.output = saved.log;
      const start = new Date(saved.startTime);
      const end = new Date(saved.lastLog);
      self.output += `\n---- Session end: ${saved.lastLog} ----\n`;
      self.output += self.formatSessionDuration(start, end);
      self.output += '\n\n';
    }
  }
  self.output += `---- Session started: ${self.startTime} ----\n\n`;
}

export default debugout;
