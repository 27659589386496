import logger from '../utils/errorLogger';
import { isNode } from './config';

function printStackTrace(errorObj) {
  const localLogger = isNode() ? console : logger;
  if (errorObj && window.navigator) {
    localLogger.log(`Message : ${errorObj.message}`);
    localLogger.log(`Stack : ${errorObj.stack}`);
  }
}

export { printStackTrace };
