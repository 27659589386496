import { makePostRequest } from '../../js/es6/fetchRequest';
import '../../assets/scss/login.scss';

export function onClickFeedback() {
    const feedbackModal = document.getElementById('feedback-modal');
    feedbackModal.classList.remove('hide');
    document.getElementById('feedback-text').value = ''
}

export function onClickClose() {
    const feedbackModal = document.getElementById('feedback-modal');
    feedbackModal.classList.add('hide');
}

export function showDropDown() {
    const dropdown = document.getElementById('parent-dropdown');
    if(dropdown.classList.contains('opened')){
        dropdown.classList.remove('opened')
    } else {
        dropdown.classList.add('opened');
    }
}

export async function sendFeedback() {
    onClickClose();
    const message = document.getElementById('feedback-text').value;
    if (message && message.trim()) {
        const {id:userId='', firstName='', accessToken, email='', accountId=''} = window.applicationUserInfo;
        const {userAgent} = navigator;
        const {roomName, hostUrl} = window.metaInfo
        const feedbackPayload = {
            message,
            name: firstName,
            roomId:roomName,
            userId,
            hostUrl,
            email,
            accountId,
            userAgent,
            isWarrningPageFeedBack:true
        }
        await makePostRequest({
            endpoint: '/api/client/v1/feedback',
            data: JSON.stringify(feedbackPayload),
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
    }
}



export function onLoading() {
    const clickHereButton = document.getElementById('click-here-id');
    const sendButton = document.getElementById('send-btn');
    const giveFeedBackButton = document.getElementById('feedback-btn');
    const closeButton = document.getElementById('close-btn');
    // const signInButton = document.getElementById('signup-li');
    const logInButton = document.getElementById('login-li');
    const dashboardButton = document.getElementById('dashboard-li');
    const {email} = window.applicationUserInfo;
    if(email){
        // signInButton.classList.add('hide');
        logInButton.classList.add('hide')
    }else {
        dashboardButton.classList.add('hide');
    }
    if (clickHereButton){
        clickHereButton.addEventListener('click', showDropDown);
    }
    if (giveFeedBackButton) {
        giveFeedBackButton.addEventListener('click', onClickFeedback);
    }
    if (closeButton) {
        closeButton.addEventListener('click', onClickClose);
    }
    if (sendButton) {
        sendButton.addEventListener('click', sendFeedback);
    }

};

export function onWindowClick(event) {
    const clickHereButton = document.getElementById('click-here-id');
    const dropdown = document.getElementById('parent-dropdown');
    if( event.target !== clickHereButton) {
        dropdown.classList.remove('opened')
    }
}
window.onload = onLoading;
window.addEventListener('click', onWindowClick );
 
